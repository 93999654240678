.header-menu-item {
  display: flex;
  align-items: center;
  padding: 15px;
  background-color: #3F876D;
  color: #eee;
  fill: #eee;
  font-family: var(--menu-font);
  border: 0;
  border-radius: 15px;
  font-weight: bold;
  cursor: pointer;
}

.header-menu-icon {
  width: 22px;
  height: 22px;
  margin-right: 10px;
}

@media screen and (max-width: 1024px) {
  .header-container {
    padding: 10px;
  }

  .header-menu-item {
    padding: 10px;
    font-size: 16px;
  }
}
