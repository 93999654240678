.guild-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.guild-container a {
  text-decoration: none;
}

.guild-header {
  font-family: var(--main-font);
  font-size: 30px;
  margin-bottom: 10px;
  font-weight: bold;
}

.guild-header-text {
  display: inline-block;
  position: relative;
}

.guild-subtitle {
  font-family: var(--main-font);
  font-size: 18px;
  color: #999;
  margin-bottom: 20px;
}

.guild-item {
  width: 100%;
  display: flex;
  padding: 20px;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
}
.guild-item:hover {
  background-color: #ddd;
}

.guild-separator {
  width: 100%;
  height: 1px;
  background-color: #ccc;
}

.guild-icon {
  width: 64px;
  height: 64px;
  border-radius: 16px;
  margin-right: 20px;
}

.guild-name {
  display: inline-block;
  font-weight: bold;
  font-size: 18px;
  font-family: var(--main-font);
  color: #444;
}

.guild-approved-icon {
  width: 32px;
  height: 32px;
  margin-left: 5px;
  margin-right: 5px;
}
.guild-approved-icon.yes {
  fill: #54B862;
}
.guild-approved-icon.no {
  fill: #E8284B;
}

.guild-enter-icon-container {
  margin-left: auto;
}

.guild-enter-icon {
  width: 32px;
  height: 32px;
}

.guild-footer {
  font-family: var(--main-font);
  color: #999;
  font-size: 14px;
  word-break: keep-all;
}

.guild-has-siamese {
  margin-left: 5px;
}

@media screen and (max-width: 1024px) {
  .guild-header {
    font-size: 20px;
    margin-bottom: 5px;
  }

  .guild-subtitle {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .guild-icon {
    width: 32px;
    height: 32px;
  }

  .guild-name {
    font-size: 14px;
  }

  .guild-footer {
    font-size: 14px;
  }
}
