.profile-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  overflow: hidden;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  margin-bottom: 15px;
}

.profile-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  font-size: 10px;
  border-radius: 5px;
  font-family: var(--menu-font);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  opacity: 0;
}

.profile-overlay:hover {
  opacity: 1;
  cursor: pointer;
  transition: opacity 300ms;
}

.profile-avatar {
  width: 100%;
  height: 100%;
  width: 50px;
  height: 50px;
  border-radius: 40px;
}

.profile-username {
  margin-left: 15px;
}

.profile-logout {
  display: none;
}

@media screen and (max-width: 1024px) {
  .profile-overlay {
    display: none;
  }
  .profile-avatar {
    width: 24px;
    height: 24px;
    border-radius: 40px;
  }
  .profile-username {
    color: white;
  }

  .profile-logout {
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px;
    box-sizing: border-box;
    margin-left: auto;
  }
}
