.embed-menu-item {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 6px 2px 6px;
  border-radius: 6px;
  background-color: rgba(0, 0, 100, 0.16);
  margin-right: 2px;
  margin-top: 4px;
}
