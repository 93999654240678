.not-found-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 80px;
}

.not-found-icon {
  width: 400px;
  height: 400px;
}
