.command-desc-container {
  padding: 15px;
  box-sizing: border-box;
  background-color: #f5f5f5;
  padding: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-sizing: border-box;
  word-break: break-all;
}

.command-desc-container img {
  max-width: 100%;
  padding: 20px;
  box-sizing: border-box;
}
