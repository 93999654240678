.home-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 32px;
}

.emoji-big {
  font-size: 160px;
}

.home-img {
  width: 400px;
  max-width: 100%;
  image-rendering: pixelated;
}
