.icon-container {
  width: 100%;
  height: 100%;
  padding: 5px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
}

.icon-drag-visualizer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  display: none;
  z-index: 999;
}

.icon-drag-add {
  width: 80%;
  height: 80%;
  display: none;
}

.icon-drag-reject {
  width: 100%;
  height: 100%;
  display: none;
}

.drag-accept .icon-drag-add {
  fill: white;
  display: block;
}

.icon-drag-reject-container {
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: white;
  font-family: var(--menu-font);
  font-size: 40px;
  padding: 10%;
  box-sizing: border-box;
}
.drag-reject .icon-drag-reject-container {
  display: flex;
}
.drag-reject .icon-drag-reject {
  fill: red;
  display: block;
}

.drag-active .icon-drag-visualizer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.icon-item-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  width: 100%;
  box-sizing: border-box;
}

.icon-item {
  display: flex;
  height: 60px;
  flex-direction: row;
  align-items: center;
  font-family: var(--main-font);
  font-size: 18px;
  color: #777;
  fill: #777;
  font-weight: bold;
  text-decoration: none;
}
.icon-item:hover {
  background-color: #ddd;
  cursor: pointer;
}

.icon-item-header {
  color: #999;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 3;
  border-bottom: 1px solid #ddd;
}
.icon-item-header:hover {
  background-color: white;
  cursor: inherit;
}

.header-name {
  margin-left: 10px;
}

.icon-item-name-container {
  display: inline-flex;
  flex: 1;
  align-items: center;
}

.icon-item-icon {
  width: 24px;
  height: 24px;
  padding: 10px;
}

.icon-item>* {
  display: inline-flex;
  align-items: center;
}

.icon-item-author {
  flex: 0 0 20%;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}

.icon-item-author-icon {
  width: 24px;
  height: 24px;
  padding: 10px;
}

.icon-item-count {
  flex: 0 0 120px;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  justify-content: center;
}

.icon-item-date {
  flex: 0 0 140px;
  justify-content: center;
}

.menu-tooltip {
  user-select: none;
  -webkit-user-drag: none;
}

.icon-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 16px;
}

.icon-footer-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  image-rendering: pixelated;
  margin-left: 16px;
}

.icon-footer-chat-bubble {
  background-color: #222;
  color: #ffffff;
  padding: 8px 14px 8px 14px;
  margin-left: 12px;
  border-radius: 4px;
  position: relative;
}
.icon-footer-chat-bubble::before {
  content: " ";
  position: absolute;
  width: 0px;
  height: 0px;
  border: 5px solid;
  border-color: transparent #222 transparent transparent;
  left: -10px;
  top: calc(50% - 5px);
}

@media screen and (max-width: 1024px) {
  .icon-container {
    padding: 0;
  }

  .header-name {
    margin-left: 5px;
  }

  .icon-item {
    height: 40px;
    font-size: 10px;
  }

  .icon-item-icon {
    width: 12px;
    height: 12px;
    padding: 5px;
  }

  .icon-item-author,
  .icon-item-date {
    display: none;
  }

  .icon-item-count {
    flex: 0 0 80px;
  }

  .icon-footer {
    display: none;
  }
}
