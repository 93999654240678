.nsfw-container {
  background-color: #feecf0;
  color: #cc0f35;
  padding: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 12px;
  box-sizing: border-box;
}

.nsfw-container a {
  color: #485fc7;
}
