.main-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.page-container {
  padding: 40px;
  padding-left: 380px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

@media screen and (max-width: 1024px) {
  .page-container {
    padding: 10px;
    padding-top: 60px;
    width: 100%;
    box-sizing: border-box;
  }
  .page-container.noscroll {
    overflow: hidden;
  }
}

