.sidebar-container {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-left: 40px;
  padding-bottom: 40px;
  margin-right: 80px;
  width: 300px;
  height: 100%;
  box-sizing: border-box;
  font-family: var(--menu-font);
  font-weight: bold;
}

.sidebar-container a {
  text-decoration: none;
}

.header-logo {
  font-size: 40px;
  font-weight: bold;
  margin-left: 20px;
  font-family: var(--logo-font);
  display: flex;
  align-items: center;
}

.header-logo-text {
  display: inline-block;
  position: relative;
  color: #222;
}

.header-logo-img {
  width: 40px;
  height: 40px;
  image-rendering: pixelated;
}

.header-burger {
  display: none;
  padding: 8px;
}

.sidebar-menu {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

.sidebar-menu-item-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.sidebar-menu-item-container.header {
  flex-direction: row;
  user-select: none;
  -webkit-user-drag: none;
}

.sidebar-menu-item {
  width: 100%;
  padding: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 20px;
  display: flex;
  box-sizing: border-box;
  color: #999;
  fill: #999;
  text-decoration: none;
  user-select: none;
  -webkit-user-drag: none;
}

.sidebar-menu-item.selected {
  background-color: #222;
  color: #eee;
  fill: #eee;
  border-radius: 15px;
}

.sidebar-menu-icon {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.sidebar-bottom {
  margin-top: auto;
  display: flex;
  flex-direction: column;
}

.sidebar-others-item {
  display: flex;
  align-items: center;
  background-color: #7289da;
  padding: 15px;
  font-size: 18px;
  border-radius: 15px;
  color: white;
  fill: white;
}

.sidebar-others-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

@media screen and (max-width: 1024px) {
  .sidebar-container {
    padding: 60px 10px 0px 10px;
    width: 100%;
    height: 0;
    overflow: hidden;
    box-sizing: border-box;
    overscroll-behavior: contain;
    z-index: 100;
  }

  .sidebar-container.visible {
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }

  .sidebar-menu {
    transform: translate(-100%);
  }

  .sidebar-container.visible .sidebar-menu {
    transition: transform 700ms;
    transform: translate(0);
  }

  .sidebar-menu-item-container.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #fff;
  }
  .sidebar-container.visible .sidebar-menu-item-container.header {
    background-color: transparent;
  }

  .sidebar-container .header-logo {
    font-size: 32px;
    margin-left: 10px;
  }

  .sidebar-container.visible .header-logo-text {
    color: white;
  }

  .header-burger {
    margin-left: 10px;
    display: inline-flex;
    align-items: center;
  }

  .header-burger-icon {
    width: 24px;
    height: 24px;
    stroke: black;
    fill: black;
    stroke-width: 8px;
  }

  .sidebar-container.visible .header-burger-icon {
    fill: white;
    stroke: white;
    stroke-width: 1px;
  }

  .sidebar-menu-item.selected {
    background-color: #777;
  }

  .sidebar-container.visible .sidebar-menu-item,
  .sidebar-container.visible .subcategory-item,
  .sidebar-container.visible .sidebar-bottom {
    color: white;
  }
}
