.command-item {
  font-size: 18px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto;
}

.command-header {
  font-size: 24px;
  padding-top: 2em;
  padding-bottom: 2em;
  box-sizing: border-box;
}

.command-title {
  display: flex;
  flex-direction: row;
  font-size: 48px;
  font-weight: bold;
  font-family: var(--menu-font);
}

.command-voice-icon {
  width: 48px;
  height: 48px;
  margin-right: 10px;
  fill: #296fa8;
}

.command-usage {
  color: #296fa8;
  background-color: #eff5fb;
  padding: 15px;
  box-sizing: border-box;
  margin-bottom: 10px;
  border-radius: 5px;
}

.command-alias {
  display: flex;
  flex-direction: row;
  font-size: 18px;
  align-items: center;
  margin-left: 15px;
  color: #777;
}

.command-alias-item {
  background-color: #48c78e;
  color: #fff;
  padding: 5px;
  margin-left: 10px;
  border-radius: 5px;
  box-sizing: border-box;
}

@media screen and (max-width: 1024px) {
  .command-item {
    font-size: 16px;
  }
  .command-header {
    font-size: 18px;
  }
  .command-title {
    font-size: 36px;
    flex-direction: column;
  }
  .command-alias {
    font-size: 14px;
    margin-bottom: 10px;
  }
}
