.auth-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: var(--menu-font);
}

.auth-header {
  font-size: 32px;
  margin-bottom: 32px;
  color: #333;
}

.auth-login-button {
  display: flex;
  align-items: center;
  background-color: #7289da;
  color: #fff;
  fill: #fff;
  padding: 15px;
  border-radius: 15px;
  font-size: 24px;
  cursor: pointer;
}

.auth-login-separator {
  display: inline-block;
  width: 1px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  margin-left: 10px;
  margin-right: 10px;
}

.auth-discord-icon {
  width: 32px;
  height: 32px;
}

@media screen and (max-width: 1024px) {
  .auth-login-button {
    font-size: 20px;
  }
}
